import React, { useState, useEffect, useContext } from 'react'
import { TranslateFunction } from 'utils/localize'
import { redirect } from 'redux-first-router'
import axios from 'axios'
import { PulseLoader } from 'react-spinners'
import Logger from 'utils/logging'
import Analytics from 'utils/analytics'
import { getAgeVerification } from 'services/api/signup/age-verification'
import { AgeVerificationType } from 'models/age-verification-model'
// import { setAfterSignupCookies } from 'features/playnow/PlayNowStorage'
import NewSignupForm from 'features/forms/signup'
import AgeVerification from 'features/forms/age-verification'
import getConfig, { isClient } from 'config/web'
import { SignupPostModel } from 'pages/signup/models/SignupModel'
import JourneyIdManager from 'proto/helpers/journeyid'
import LauncherTokenManager from 'events/download-event/helpers/launcher-token'
import VerificationManager from 'packages/authentication/components/verification-callback/VerificationManager'
import { UserContext } from 'utils/context/UserContext'
import { getQuery } from 'lib/location/selectors'
import { useSelector } from 'react-redux'
import { convertExpiresSecondsToTime } from 'packages/authentication/helpers/token'
import { loginSuccess } from 'packages/authentication/lib/actions'
import JourneyValuesManager from 'proto/helpers/journeyvalues'
import { GlobalState } from 'types'
import validateEmail from 'pages/signup/validations/validateEmail' // TODO move these to helpers
import validateUsername from 'pages/signup/validations/validateUsername'
import validatePassword from 'pages/signup/validations/validatePassword'
import { hashData } from 'utils/hash/hash-data'
import { setAfterSignupCookies } from 'features/playnow/PlayNowStorage'
import style from './SignupForm.module.scss'

interface Props {
    invc?: string
    language: string
    emailVerificationSuccessUrl: string
    emailVerificationFailedUrl: string
    showLogin?: boolean
    translate: TranslateFunction
    dispatch: (action: unknown) => void
    onHasLoaded?: () => unknown
    theme?: 'dark' | 'light' | 'quadrant'
}

const { webBaseUrl, signupServiceUrl } = getConfig()

const SignupForm = ({
    invc,
    language,
    emailVerificationSuccessUrl,
    emailVerificationFailedUrl,
    showLogin,
    translate,
    dispatch,
    onHasLoaded,
    theme,
}: Props): JSX.Element => {
    const [
        verification,
        setVerification,
    ] = useState<AgeVerificationType | null>(null)

    // Test age verification
    const [forceKRCountry, setForceKRCountry] = useState(false)
    const [forceVerificationData, setForceVerificationData] = useState(false)

    const { country } = useContext(UserContext)
    const query = useSelector((state: GlobalState) => getQuery(state))

    const journeyIdManager = new JourneyIdManager()
    const launcherTokenManager = new LauncherTokenManager()
    const verificationManager = new VerificationManager()

    const api = signupServiceUrl

    useEffect(() => {
        if (query?.crypticCountry === 'kr') {
            setForceKRCountry(true)
        }

        if (query?.forceVerification === 'true') {
            console.log('forcing verification values')
            setForceVerificationData(true)
        }

        const ageVerification = async (countryCode: string): Promise<void> => {
            const data = await getAgeVerification(countryCode)

            if (data) {
                setVerification(data)
            }
        }
        if (country) {
            const countryCodeForverification = forceKRCountry ? 'kr' : country
            ageVerification(countryCodeForverification)
        }
    }, [forceKRCountry, country])

    useEffect(() => {
        if (onHasLoaded) {
            onHasLoaded()
        }
    }, [])

    const generateEmailLink = (url: string): string => {
        const lang = language === 'en' ? '' : `/${language}`

        return `${webBaseUrl}${lang}/${url}`
    }

    const signupSuccessRedirect = (
        userToken: string,
        userName: string
    ): void => {
        ;(async () => {
            // Clear sessionStorage for users who have been through the Payletter flow
            verificationManager.removeSessionStorage(
                verificationManager.sessionFullName
            )
            verificationManager.removeSessionStorage(
                verificationManager.sessionTid
            )
            verificationManager.removeSessionStorage(
                verificationManager.sessionauthInfo
            )
            verificationManager.removeSessionStorage(
                verificationManager.sessionEmail
            )

            // Redirect signup-verify to correct lang if needed.
            const lang = language === 'en' ? null : language

            const payload = {
                lang,
                page: 'signup-verify',
                query: {
                    rec: invc ? 'true' : 'false',
                    signupSuccess: 'true',
                },
            }

            dispatch(
                redirect({
                    type: 'PAGE',
                    payload,
                })
            )
        })()
    }

    const handlePasswordValidationResponse = (response): string => {
        if (response === 'Password is not valid') {
            // TODO replace with new datalayer event helper.
            const validationEvent = {
                event: 'Interaction',
                eventCategory: 'form interactions',
                eventAction: 'form: signup',
                eventLabel: 'error - password is not secure',
            }
            Analytics.PushEventObject(validationEvent)
            console.log('returning bad password')
            return translate(
                'signup.validation.passwordNotValidOrBreached'
            ).toString()
        }
        return response
    }

    const handleSubmit = (
        fields,
        { setSubmitting, setErrors, setFieldValue },
        recaptchaValue
        // isUpSell
    ): void => {
        // TODO Error check fields again? with services?

        const journeyValuesManager = new JourneyValuesManager(isClient)

        const signupPostBody: SignupPostModel = {
            username: fields.username,
            password: fields.password,
            emailAddress: fields.email,
            language,
            recruitToken: invc || '',
            agreedTermsOfService: fields.agreedTerms,
            country: forceKRCountry ? 'kr' : country,
            emailVerificationSuccessUrl: generateEmailLink(
                emailVerificationSuccessUrl
            ),
            emailVerificationFailedUrl: generateEmailLink(
                emailVerificationFailedUrl
            ),
            recaptchaType: 'Invisible',
            'g-recaptcha-response': recaptchaValue,
            journeyId: journeyIdManager.getJourneyId(),
            ageVerificationTid: verificationManager.getSessionStorage(
                verificationManager.sessionTid
            ),
            ageVerificationAuthInfo: verificationManager.getSessionStorage(
                verificationManager.sessionauthInfo
            ),
            context: journeyValuesManager.getJourneyValues(),
        }

        // force test values and debugging
        if (forceVerificationData && !signupPostBody.ageVerificationTid) {
            signupPostBody.country = 'kr'
            signupPostBody.ageVerificationTid = `test_web_transaction_id_${signupPostBody.username}`
            signupPostBody.ageVerificationAuthInfo = `test_web_auth_info_${signupPostBody.username}`
            console.log(
                'test values set in signup form, signupPostBody: ',
                signupPostBody
            )
        }

        axios
            .post(`${api}/api/v2/signup/web`, signupPostBody)
            .then((response) => {
                console.log('signupPostBody: ', signupPostBody)
                if (response.status === 201 && response.data.userCreated) {
                    // Pushing userId to data layer with correct properties.
                    const userId = response.data.hashedUserId
                    Analytics.PushEventObject({ UserID: userId })
                    // Hashing email and pushing to data layer.
                    hashData(signupPostBody.emailAddress).then(
                        (hashedEmail: string) => {
                            Analytics.PushEventObject({
                                userEmail: hashedEmail,
                            })
                        }
                    )
                    const {
                        userToken,
                        userRefreshToken,
                        playToken,
                        launcherToken,
                    } = response.data
                    const { username } = signupPostBody

                    if (launcherToken) {
                        launcherTokenManager.setLauncherToken(launcherToken)
                    }

                    const doLogin = (): void => {
                        dispatch(
                            loginSuccess(
                                userToken,
                                convertExpiresSecondsToTime(3600),
                                userRefreshToken
                            )
                        )
                        // I think we can remove this.
                        setAfterSignupCookies(userToken, username, playToken)
                    }
                    // Log in new user right away and set cookies.
                    doLogin()

                    signupSuccessRedirect(userToken, username)
                }
            })
            .catch((error) => {
                if (error.response) {
                    // 409 is conflict, meaning that something is wrong with the input. Getting errors and adding to form validation
                    if (error.response.status === 409) {
                        if (error.response.data && error.response.data.errors) {
                            const errors = error.response.data.errors

                            setErrors({
                                email: errors.emailAddress,
                                username: errors.username,
                                password: handlePasswordValidationResponse(
                                    errors.password
                                ),
                                agreedTerms: errors.agreedTermsOfService,
                            })
                            setFieldValue('recaptcha', null)
                            setSubmitting(false)
                        }
                    } else {
                        // Another error occured. Adding it to common error display in form to show to user.
                        setErrors({
                            global: translate(
                                'common.error',
                                { code: `(${error.response.status})` },
                                { renderInnerHtml: true }
                            ),
                        })
                        setSubmitting(false)
                        // TODO NOT USED !!

                        Logger.captureException(error, null, {
                            category: 'signup',
                            location: 'handleSubmit',
                            message: error.message,
                        })
                    }
                } else {
                    Logger.captureException(error, null, {
                        category: 'signup',
                        location: 'handleSubmit',
                        message: error.message,
                    })
                }
            })
    }

    const renderLoader = (): JSX.Element => {
        return (
            <div className={style.loader}>
                <PulseLoader size={30} color="#5CCBCB" loading />
            </div>
        )
    }

    const renderForm = (): JSX.Element => {
        if (
            verification?.required &&
            verification?.provider &&
            sessionStorage.getItem('PayletterVerificationFullName') === null
        ) {
            return <AgeVerification provider={verification.provider} />
        }

        return (
            <>
                {sessionStorage.getItem('PayletterVerificationFullName') && (
                    <div style={{ display: 'none' }}>
                        Welcome:{' '}
                        {sessionStorage.getItem(
                            'PayletterVerificationFullName'
                        )}
                    </div>
                )}

                <NewSignupForm
                    handleSubmit={handleSubmit}
                    validateEmail={validateEmail}
                    validateUsername={validateUsername}
                    validatePassword={validatePassword}
                    showLogin={showLogin}
                    showRecruitLogin={invc !== undefined}
                    theme={theme}
                    //   hasUpsell={this.props.hasUpsell}
                />
                {/* Can be added in A/B test to overwrite manual vs auto */}
                {/* <input id="download" value="false" type="hidden" /> */}
            </>
        )
    }

    return verification ? renderForm() : renderLoader()
}

SignupForm.defaultProps = {
    showLogin: true,
    theme: 'dark',
}

export default SignupForm
